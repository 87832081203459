import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_HOSPITAL_DEPARTMENT, GET_HOSPITAL_DEPARTMENTS,
  STORE_HOSPITAL_DEPARTMENT,
  UPDATE_HOSPITAL_DEPARTMENT
} from "../store/modules/hospitalDepartment.module";
export  default {
  methods: {
    storeHospitalDepartment(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_HOSPITAL_DEPARTMENT, formData), actions)
    },
    getHospitalDepartment(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_HOSPITAL_DEPARTMENT, id), actions)
    },
    updateHospitalDepartment(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_HOSPITAL_DEPARTMENT, { id, formData }), actions)
    },
    getHospitalDepartments(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_HOSPITAL_DEPARTMENTS, page), actions)
    },
  }
}
