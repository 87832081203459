<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="hospitalDepartmentsMeta" @refresh="onRefresh" @change-page="onChangePage">
        <b-button slot="action" :to="{name: `department.store`}" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="6" md="3" v-for="item in hospitalDepartments" :key="item.id" class="d-flex">
          <portlet foot-class="mt-auto">
            <template v-slot:cleanBody>
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item" v-if="item.hasOwnProperty(lang)">
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("PAGES.HOSPITAL_DEPARTMENT.HOME.LABEL.title") }}</span>
                        <span class="kt-widget__data">{{ item[lang].title }}</span>
                      </div>
                      <div class="kt-widget__contact cursor-default" v-if="item[lang].describe">
                        <span class="kt-widget__data">{{ item[lang].describe }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot>
              <b-button-group>
                <b-button block variant="primary" :pressed="false" :to="{name: `department.edit`, params: {id: item.id}}">{{ $t('COMMON.FORM.BUTTONS.EDIT') }}</b-button>
                <b-button variant="danger" :pressed="false" @click="onChangeSwitch(item.id, 'is_show', item.is_show)"><span class="fa " :class="[item.is_show ? 'fa-eye' : 'fa-eye-slash']"></span></b-button>
              </b-button-group>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import hospitalDepartmentMixin from "@m@/hospitalDepartment.mixin";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import {
    SET_HOSPITAL_DEPARTMENT_ERROR,
    SET_HOSPITAL_DEPARTMENTS
  } from "../../../../store/modules/hospitalDepartment.module";
  export default {
    name: "index",
    components: { Error, Portlet, pagination },
    mixins: [hospitalDepartmentMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {},
    created() {
      this.autoLoadHomeData(1);
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.HOSPITAL_DEPARTMENT.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'department.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.HOSPITAL_DEPARTMENT.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    computed: {
      ...mapGetters([
         'hospitalDepartments', 'hospitalDepartmentsMeta', 'hospitalDepartmentsPage'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        loading: false,
        page: 1
      }
    },
    methods: {
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {hospitalDepartmentsPage} = this;
        if( !rootPage ){
          this.page = hospitalDepartmentsPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getHospitalDepartments(this.page)
          .catch(err=>{
            console.info('getHospitalDepartments.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      onChangeSwitch(id, column, isShow){
        this.msgBoxConfirm({
          message: isShow ? this.$t("PAGES.HOSPITAL_DEPARTMENT.HOME.CONFIRM_TIPS.CLOSE") : this.$t("PAGES.HOSPITAL_DEPARTMENT.HOME.CONFIRM_TIPS.OPEN"),
          confirmCallback: confirm=>{
            if ( confirm ){
              this.switchColumn({
                resource: 'hospital-departments',
                id, column, mutationType: SET_HOSPITAL_DEPARTMENT_ERROR,
                begin: ()=>{ this.loading = true },
                after: ()=>{ this.loading = false },
              }).then((res)=>{
                let { is_show } = res.data;
                let { hospitalDepartments, hospitalDepartmentsMeta } = this;
                let tmp = hospitalDepartments.map(item=>{
                  if( item.id === id ){
                    item.is_show = is_show;
                  }
                  return item;
                });
                this.$store.commit(SET_HOSPITAL_DEPARTMENTS, { data: tmp, meta: hospitalDepartmentsMeta});
              })
            }
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
