<template>
  <div class="d-flex kt-wizard-v4" id="kt_wizard_v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">

      <!--doc: Remove "kt-wizard-v4__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.ONE.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.ONE.DESC") }}
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.TWO.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.TWO.DESC") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end: Form Wizard Nav -->
    <portlet body-fit body-class="border-start-0" class="border-start-0">
      <template v-slot:body>
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

            <!--begin: Form Wizard Form-->

            <div class="kt-form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.ONE.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-title" :label="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.LABEL.title')" label-for="title_ug">
                          <b-form-input id="title_ug" class="direction-rtl" v-model="formData['zh-UG'].title" :state="validateState('title', 'zh-UG')" aria-describedby="title-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.PLACEHOLDER.title_ug')" />
                          <b-form-invalid-feedback id="title-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="9">
                        <b-form-group id="input-group-describe-ug" :label="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.LABEL.describe')" label-for="describe_ug">
                          <b-form-input class="direction-rtl" id="describe_ug" v-model="formData['zh-UG'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.PLACEHOLDER.describe_ug')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.STEP.TWO.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-title-cn" :label="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.LABEL.title')" label-for="title_cn">
                          <b-form-input id="title_cn" class="direction-ltr" v-model="formData['zh-CN'].title" :state="validateState('title', 'zh-CN')" aria-describedby="title-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.PLACEHOLDER.title_cn')" />
                          <b-form-invalid-feedback id="title-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="9">
                        <b-form-group id="input-group-describe-cn" :label="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.LABEL.describe')" label-for="describe_cn">
                          <b-form-input class="direction-ltr" id="describe_cn" v-model="formData['zh-CN'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.PLACEHOLDER.describe_cn')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <div class="kt-wizard-v4__other">
                <b-row>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-is_show" :label="$t('COMMON.FORM.LABEL.is_show')" label-for="is_show">
                      <b-form-radio-group
                        id="is_show"
                        v-model="formData.is_show"
                        :options="yesOrNot"
                        buttons />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-9" :label="$t('COMMON.FORM.LABEL.sort')" label-for="sort">
                      <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" :placeholder="$t('COMMON.FORM.PLACEHOLDER.sort')" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                  {{ $t("COMMON.FORM.BUTTONS.PREV") }}
                </button>
                <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
                  {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
                </button>
                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                  {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
                </button>
              </div>
              <!--end: Form Actions -->
            </div>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import hospitalDepartmentMixin from "@m@/hospitalDepartment.mixin"
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  export default {
    name: "index",
    components: {Portlet},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
    },
    mixins: [hospitalDepartmentMixin, validateStateMixin, validationMixin, sweetAlertMixin],
    created() {
      if (this.action === 'edit'){
        this.getHospitalDepartment(this.id)
          .then(res=>{
            this.formData = Object.assign({}, this.formData, res.data);
          })
          .catch(err=>{
            console.info('getHospitalDepartment.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    validations(){
      let validates = {};
      validates['zh-UG'] = {
        title: { required }
      };
      validates['zh-CN'] = {
        title: { required }
      };
      return {
        formData: validates
      }
    },
    data() {
      return {
        loading: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          sort: null,
          is_show: true,
          "zh-UG": {
            title: null,
            describe: null,
            language: 'zh-UG'
          },
          "zh-CN": {
            title: null,
            describe: null,
            language: 'zh-CN'
          }
        }
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeHospitalDepartment(this.formData)
            .then(()=> {
              this.$router.replace({ name: `department.home` });
            }).catch(err=>{
              console.info('storeHospitalDepartment.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          delete this.formData.lang;
          this.updateHospitalDepartment(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateHospitalDepartment.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v4', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', wizardObj){
        if( formName ){
          this.$v.formData[formName].$touch();
        }else {
          this.$v.formData.$touch();
        }
        // if (this.$v.formData[formName].$anyError === true) {
        if (this.$v.formData.$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.HOSPITAL_DEPARTMENT_FORM.FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        switch (currentStep) {
          case 1:
            this.validHandler("zh-UG", wizardObj);
            break;
          case 2:
            this.validHandler("zh-CN", wizardObj);
            break;
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-4";
</style>
